///
/// This will prepare the search term.
///
export const prepareSearch = (rawSearchTerm: string): string => {
  // iOS uses a unicode apostrophe and quotes - we want to replace them with the regular ones.
  let searchTermPrepared = rawSearchTerm
    .replace(new RegExp(/[\u0060\u00B4\u2018\u2019]/gm), "'")
    .replace(new RegExp(/[\u201c\u201d]/gm), '"')
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')

  return searchTermPrepared
}

///
/// Returns true if the text matches the prepared search term.
///
export const checkSearchMatch = (preparedSearchTerm: string, text: string): boolean => {
  if (preparedSearchTerm.length < 3) {
    // Not enough search term.
    return true
  } else {
    return (
      text
        .toLowerCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .indexOf(preparedSearchTerm) >= 0
    )
  }
}
