import {useCallback, useMemo} from 'react'
import {Col, Container, Row, ToggleButton} from 'react-bootstrap'
import {decadeOfReleaseInfos} from 'src/helper/filter'
import usePhonebook from 'src/providers/phonebook'

///
/// Data for the decade of release.
///
export type DecadeFilterInfo = {
  title: string
  startYear: number
  enabled: boolean
}

export type DecadeFilter = {
  filterDecades: Set<number>
}

export const decadeFilterInit = (): DecadeFilter => {
  return {filterDecades: new Set()}
}

export type Props = {
  decadeFilter: DecadeFilter
  onDecadeFilterUpdate: (newDecadeFilter: DecadeFilter) => void
}

type FilterControlProps = {
  filterInfo: DecadeFilterInfo
  decadeFilter: DecadeFilter
  onSelectionChange: (newCheckState: boolean, filterInfo: DecadeFilterInfo) => void
}

///
/// Displays a control for a single decade of release.
///
const FilterControl = ({filterInfo, decadeFilter, onSelectionChange}: FilterControlProps) => {
  const isChecked = decadeFilter.filterDecades.has(filterInfo.startYear)
  const text = isChecked ? `${filterInfo.title} ✅` : `${filterInfo.title}`
  const variant = isChecked ? 'secondary' : 'outline-secondary'
  return (
    <ToggleButton
      id={'id_' + filterInfo.title}
      type="checkbox"
      variant={variant}
      disabled={!filterInfo.enabled}
      checked={isChecked}
      value="1"
      onChange={(event) => onSelectionChange(event.target.checked, filterInfo)}>
      {text}
    </ToggleButton>
  )
}

///
/// The controls for the DecadeOfRelease filtering controls.
///
const DecadeOfRelease = ({decadeFilter, onDecadeFilterUpdate}: Props) => {
  const {phonebook} = usePhonebook()

  const decadeFilterInfos: DecadeFilterInfo[] = useMemo(() => {
    // List of all filters to display/adjust.
    let decadeFilterInfoList: DecadeFilterInfo[] = []

    // The first entry is a "before the.." entry.
    decadeFilterInfoList.push({
      title: `Before ${decadeOfReleaseInfos[0].yearStart}`,
      startYear: 0,
      enabled: false,
    })

    // Now, add each decade.
    for (let i = 0; i < decadeOfReleaseInfos.length; i++) {
      decadeFilterInfoList.push({
        title: `The ${decadeOfReleaseInfos[i].yearStart}s`,
        startYear: decadeOfReleaseInfos[i].yearStart,
        enabled: false,
      })
    }

    // Disable all the filters.
    decadeFilterInfoList.map((info) => (info.enabled = false))

    // Now go through all the albums, and enable the right infos.
    const highestIndex = decadeFilterInfoList.length - 1
    const earliestDecade = decadeFilterInfoList[0].startYear
    const latestDecade = decadeFilterInfoList[highestIndex].startYear
    phonebook.tracks.forEach((track) => {
      if (track.release_year < earliestDecade) {
        // This is a very early track.
        decadeFilterInfoList[0].enabled = true
      }
      if (track.release_year >= latestDecade) {
        // This is a very late track.
        decadeFilterInfoList[highestIndex].enabled = true
      }

      // It's somewhere in between.
      for (let index = 1; index < highestIndex; index++) {
        const info = decadeFilterInfoList[index]
        if (track.release_year >= info.startYear && track.release_year < info.startYear + 10) {
          //Enable this decade.
          info.enabled = true
        }
      }
    })
    return decadeFilterInfoList
  }, [phonebook.tracks])

  const totalDecades = decadeFilterInfos.length

  const onFilterSelectionCheckChange = useCallback(
    (newCheckState: boolean, filterInfo: DecadeFilterInfo) => {
      // One of the checkboxes has changed.
      if (newCheckState) {
        // This has been checked.
        decadeFilter.filterDecades.add(filterInfo.startYear)
      } else {
        // The checkbox has been turned off.
        decadeFilter.filterDecades.delete(filterInfo.startYear)
      }
      onDecadeFilterUpdate({...decadeFilter})
    },
    [decadeFilter, onDecadeFilterUpdate],
  )

  return (
    <Container>
      {decadeFilterInfos.map((info, index) => {
        if (index % 2 === 0) {
          if (index >= totalDecades - 1) {
            return (
              <Row key={index}>
                <Col fluid className="text-center" style={{marginBottom: '10px'}}>
                  <FilterControl
                    filterInfo={decadeFilterInfos[index]}
                    decadeFilter={decadeFilter}
                    onSelectionChange={onFilterSelectionCheckChange}
                  />
                </Col>
              </Row>
            )
          } else {
            return (
              <Row key={index}>
                <Col fluid className="text-center" style={{marginBottom: '10px'}}>
                  <FilterControl
                    filterInfo={decadeFilterInfos[index]}
                    decadeFilter={decadeFilter}
                    onSelectionChange={onFilterSelectionCheckChange}
                  />
                </Col>
                <Col fluid className="text-center" style={{marginBottom: '10px'}}>
                  <FilterControl
                    filterInfo={decadeFilterInfos[index + 1]}
                    decadeFilter={decadeFilter}
                    onSelectionChange={onFilterSelectionCheckChange}
                  />
                </Col>
              </Row>
            )
          }
        } else {
          return null
        }
      })}
    </Container>
  )
}

export default DecadeOfRelease
