import {Routes} from './Navigation'
import ContentPage from './ContentPage'
import usePhonebook from '../providers/phonebook'
import {useMemo} from 'react'
import {prepareSearch} from '../helper/search'
import {useArtistSearch} from '../providers/search'
import GroupedTracksList from './GroupedTracksList'
import {useFilterData} from 'src/providers/filter'
import {phoneBookArtistsFilterAndSort} from 'src/helper/phonebook'

const ArtistsPage = () => {
  const {searchTerm, setSearchTerm} = useArtistSearch()
  const {filterData} = useFilterData()
  const {phonebook} = usePhonebook()

  const artistInfos = useMemo(() => {
    return phoneBookArtistsFilterAndSort(phonebook, prepareSearch(searchTerm), filterData)
  }, [searchTerm, phonebook, filterData])

  ///
  /// Called to actually perform the search.
  ///
  const onPerformSearch = (searchTermUpdate: string) => {
    setSearchTerm(searchTermUpdate)
  }

  ///
  /// Called when the search-term is cleared - need to display all tracks.
  ///
  const onSearchTermClear = () => {
    setSearchTerm('')
  }

  return (
    <ContentPage
      activeRoute={Routes.tracks}
      onPerformSearch={onPerformSearch}
      onSearchTermClear={onSearchTermClear}
      initialSearchTerm={searchTerm}
      itemTextSingular="artist"
      hasData={artistInfos.length > 0}
      placeholderText={`Search ${phonebook.artists.size} artists...`}>
      <GroupedTracksList groupedTracksInfos={artistInfos} />
    </ContentPage>
  )
}

export default ArtistsPage
