import {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import TrackList from './TrackList'
import {GroupedTracksInfo} from 'src/helper/greoupedTracksInfo'

type Props = {
  groupedTracksInfos: GroupedTracksInfo[]
  minToShowAllTracks?: number
}

type AllTracksTriggerProps = {
  groupedTracksInfos: GroupedTracksInfo[]
  minToShowAllTracks?: number
  onTap: () => void
}

const AllTracksTrigger: React.FC<AllTracksTriggerProps> = ({
  groupedTracksInfos,
  minToShowAllTracks,
  onTap,
}: AllTracksTriggerProps) => {
  if (
    groupedTracksInfos.length <= 1 ||
    (minToShowAllTracks && groupedTracksInfos.length > minToShowAllTracks)
  ) {
    return null
  }
  return (
    <div style={{paddingBottom: 25}}>
      <h3 onClick={onTap} style={{width: '100%', textAlign: 'center'}}>
        [See all tracks...]
      </h3>
    </div>
  )
}

type ModalInfo = {
  title: string
  singleGroup?: GroupedTracksInfo
  multiGroup?: GroupedTracksInfo[]
}

///
/// Displays a list of grouped tracks. The list will show the name of each group, and when tapped
/// will open a full-screen modal showing all the tracks in that group.
///
const GroupedTracksList: React.FC<Props> = ({groupedTracksInfos, minToShowAllTracks}: Props) => {
  const [modalInfo, setModalInfo] = useState<ModalInfo | undefined>(undefined)

  const onGroupClick = (groupedTracksInfo: GroupedTracksInfo) => {
    setModalInfo({title: groupedTracksInfo.name, singleGroup: groupedTracksInfo})
  }

  const closeModal = () => {
    setModalInfo(undefined)
  }

  const onAllTracksClick = () => {
    setModalInfo({title: 'All Tracks', multiGroup: groupedTracksInfos})
  }

  return (
    <>
      <div style={{background: '#F7AD19', paddingTop: 20}}>
        <>
          <AllTracksTrigger
            groupedTracksInfos={groupedTracksInfos}
            minToShowAllTracks={minToShowAllTracks}
            onTap={onAllTracksClick}
          />
          {groupedTracksInfos.map((groupedTracksInfo, index) => {
            return (
              <div key={index} style={{paddingBottom: 25}}>
                <h3
                  onClick={() => onGroupClick(groupedTracksInfo)}
                  style={{width: '100%', textAlign: 'center'}}>
                  {groupedTracksInfo.name}
                </h3>
              </div>
            )
          })}
        </>
      </div>
      <Modal
        show={modalInfo !== undefined}
        onHide={closeModal}
        fullscreen="sm-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header style={{background: '#D78D00'}}>
          <Modal.Title id="contained-modal-title-vcenter">{modalInfo?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background: '#D78D00'}}>
          <TrackList
            trackInfos={modalInfo?.singleGroup?.tracks}
            groupedTrackInfos={modalInfo?.multiGroup}
          />
        </Modal.Body>
        <Modal.Footer style={{background: '#D78D00'}}>
          <Button onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default GroupedTracksList
