import {createContext, useCallback, useContext, useState} from 'react'
import {FilterData, filterDataInit} from 'src/helper/filter'

///
/// The actions for the filter trigger.
///
type FilterTriggerActions = {
  // Returns true when at least one filtering control has been set.
  // (False means there's no filtering)
  isFilterActive: () => boolean

  // Call this to set the state of the filter-controls UI; either "open" or
  // "closed". It's not an error to call the same state multiple times. Note
  // that when closing the controls UI, the UI will close immediately.
  setFilterControlsUI: (state: 'open' | 'closed') => void

  /// Returns true if the filter-controls UI is active.
  checkFilterControlsUIActive: () => boolean
}

///
/// Actions for the actual filter data.
///
type FilterDataActions = {
  getFilterData: () => FilterData
  setFilterData: (newFilterData: FilterData) => void
  clearFilterData: () => void
}

///
/// All of the data stored for the filter context - including the actual data,
/// but also actions which can be performed.
///
type FilterContextData = {
  data: FilterData
  filterTriggerActions: FilterTriggerActions
  filterDataActions: FilterDataActions
}

//
// Create the actual context - with default (place-holder) functions for the
// actions.
//
const FilterContext = createContext<FilterContextData>({
  data: filterDataInit(),
  filterTriggerActions: {
    isFilterActive: () => false,
    setFilterControlsUI: (state: 'open' | 'closed') => {},
    checkFilterControlsUIActive: () => false,
  },
  filterDataActions: {
    getFilterData: filterDataInit,
    setFilterData: (newFilterData: FilterData) => {},
    clearFilterData: () => {},
  },
})

//
// Internal method to get the context - all external methods wrap around this.
//
const useFilterContext = () => {
  return useContext(FilterContext)
}

//
// The actual provider for the filtering options - which are shared across all pages
// of the application.
//
export const FilterProvider = ({children}: {children: React.ReactNode}) => {
  // Holds the state of all the filtering options.
  const [filterData, setFilterData] = useState<FilterData>(filterDataInit())

  // Indicates whether the filter-control screen should be active.
  const [filterControlsActive, setFilterControlsActive] = useState<boolean>(false)

  // Returns true if any filtering option is active.
  const isFilterActive = useCallback(() => {
    if (filterData.dateAddedFilter.infoIndex !== 0) {
      return true
    } else if (filterData.decadeOfReleaseFilters.size > 0) {
      return true
    }
    return false
  }, [filterData])

  const setFilterControlsUI = useCallback((state: 'open' | 'closed') => {
    if (state === 'open') {
      setFilterControlsActive(true)
    } else {
      setFilterControlsActive(false)
    }
  }, [])

  const clearFilterData = useCallback(() => {
    setFilterData(filterDataInit())
  }, [])

  const checkFilterControlsUIActive = useCallback(() => {
    return filterControlsActive
  }, [filterControlsActive])

  // The entire context to return.
  const filterContextData: FilterContextData = {
    data: filterData,
    filterTriggerActions: {
      isFilterActive: isFilterActive,
      setFilterControlsUI: setFilterControlsUI,
      checkFilterControlsUIActive: checkFilterControlsUIActive,
    },
    filterDataActions: {
      getFilterData: () => filterData,
      setFilterData: setFilterData,
      clearFilterData: clearFilterData,
    },
  }

  return <FilterContext.Provider value={filterContextData}>{children}</FilterContext.Provider>
}

///
/// This allows components to use the Filter Trigger actions.
///
export const useFilterTriggerActions = (): FilterTriggerActions => {
  return useFilterContext().filterTriggerActions
}

///
/// This allows components to use the Filter Data actions.
///
export const useFilterData = (): {
  filterData: FilterData
  setFilterData: (newFilterData: FilterData) => void
  clearFilterData: () => void
} => {
  const {data, filterDataActions} = useFilterContext()
  return {
    filterData: data,
    setFilterData: filterDataActions.setFilterData,
    clearFilterData: filterDataActions.clearFilterData,
  }
}
