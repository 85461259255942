import {Image} from 'react-bootstrap'
import phone from '../assets/phone.jpg'

const NotFound = () => {
  return (
    <div
      style={{
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
      }}>
      <div
        style={{
          backgroundColor: '#FFAAAA',
          borderRadius: 20,
          borderStyle: 'solid',
          borderWidth: 3,
          borderColor: '#FF1111',
          padding: 30,
          marginTop: 30,
          marginLeft: 10,
          marginRight: 10,
          textAlign: 'center',
        }}>
        <h3>Amazing Rotary Jukebox</h3>
        <br />
        <p>
          The page you linked to doesn't exist. Suggest starting at <a href="/">the beginning</a>.
        </p>
      </div>
      <div style={{textAlign: 'center'}}>
        <Image fluid style={{marginTop: 50, objectFit: 'scale-down'}} src={phone} />
      </div>
    </div>
  )
}

export default NotFound
