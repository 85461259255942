import Nav from 'react-bootstrap/Nav'
import {NavLink} from 'react-router-dom'
import FilterTrigger from './Filter/trigger'

export const Routes = {
  tracks: '/tracks',
  artists: '/artists',
  genres: '/genres',
}

type NavigationProps = {
  activeRoute: string
}

const Navigation = (props: NavigationProps) => {
  return (
    <Nav
      variant="pills"
      className="justify-content-center"
      defaultActiveKey={props.activeRoute}
      style={{paddingTop: 10, paddingBottom: 10}}>
      <Nav.Item>
        <Nav.Link as={NavLink} to={Routes.tracks}>
          Tracks
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to={Routes.artists}>
          Artists
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to={Routes.genres}>
          Genres
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <div style={{height: '100%', alignContent: 'center', marginLeft: 10}}>
          <FilterTrigger />
        </div>
      </Nav.Item>
    </Nav>
  )
}

export default Navigation
