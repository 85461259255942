import {Dropdown, DropdownButton, Stack} from 'react-bootstrap'
import {dateInfoTable} from 'src/helper/filter'

type Props = {
  dateInfoIndex: number
  onDateInfoSelected: (dateInfoIndex: number) => void
}

const DateDropdown = ({dateInfoIndex, onDateInfoSelected}: Props) => {
  if (dateInfoIndex === undefined) {
    return null
  }

  return (
    <Stack direction="horizontal" gap={3} className="justify-content-center">
      <div>Tracks added since:</div>
      <DropdownButton
        title={dateInfoTable[dateInfoIndex].title}
        size="sm"
        variant="secondary"
        onSelect={(eventKey: any) => {
          onDateInfoSelected(Number(eventKey))
        }}>
        {dateInfoTable.map((dateInfo, index) => {
          if (index === dateInfoIndex) {
            return null
          }
          return (
            <Dropdown.Item key={index} eventKey={index}>
              {dateInfo.title}
            </Dropdown.Item>
          )
        })}
      </DropdownButton>
    </Stack>
  )
}

export default DateDropdown
