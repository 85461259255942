import {TrackInfo} from './trackInfo'

///
/// The actual filter data which will apply to all the searches.
///
export type FilterData = {
  dateAddedFilter: DateAddedFilter
  decadeOfReleaseFilters: Set<number>
}

export type DateAddedFilter = {
  infoIndex: number
  cutoffTimestamp: number
}

export const filterDataInit = (): FilterData => {
  return {dateAddedFilter: {infoIndex: 0, cutoffTimestamp: 0}, decadeOfReleaseFilters: new Set()}
}

/// What an entry looks like in the date-table, for the "date added to the jukebox" filter. It's position in
/// the table serves as its ID.
export type DateInfo = {
  title: string
  delta?: number
}

const secondsInDay = 86400
const secondsInWeek = secondsInDay * 7
const secondsInMonth = secondsInWeek * 4
const secondsInYear = secondsInMonth * 10

// All of the entries for the "date added to the jukebox" filter.
export const dateInfoTable: DateInfo[] = [
  {
    title: 'The Beginning',
    delta: undefined,
  },
  {
    title: 'Today',
    delta: secondsInDay,
  },
  {
    title: 'Two Weeks',
    delta: secondsInWeek * 2,
  },
  {
    title: 'One Month',
    delta: secondsInMonth,
  },
  {
    title: 'Three Months',
    delta: secondsInMonth * 3,
  },
  {
    title: 'Six Months',
    delta: secondsInMonth * 6,
  },
  {
    title: 'Nine Months',
    delta: secondsInMonth * 9,
  },
  {
    title: 'One Year',
    delta: secondsInYear,
  },
]

///
/// Information for a Decade Of Release.
///
export type DecadeOfReleaseInfo = {
  yearStart: number
}

///
/// List of "decade of release" that the application supports.
///
export const decadeOfReleaseInfos: DecadeOfReleaseInfo[] = [
  {yearStart: 1940},
  {yearStart: 1950},
  {yearStart: 1960},
  {yearStart: 1970},
  {yearStart: 1980},
  {yearStart: 1990},
  {yearStart: 2000},
  {yearStart: 2010},
  {yearStart: 2020},
]

///
/// This converts the year-of-release to a decade filter. (Note that a return value of 0 indicates
/// that the year-of-release is earlier than the first entry in the decadeOfReleaseInfos).
///
const yearOfReleaseToDecadeFilter = (yearOfRelease: number): number => {
  if (yearOfRelease < decadeOfReleaseInfos[0].yearStart) {
    // Year is before our table.
    return 0
  }

  const highestDecadeFilter = decadeOfReleaseInfos[decadeOfReleaseInfos.length - 1].yearStart
  if (yearOfRelease >= highestDecadeFilter) {
    // Year is beyond our table
    return highestDecadeFilter
  }

  // Now that we know we're in range, we can convert to a decade filter number by, essentially,
  // rounding down.
  return yearOfRelease - (yearOfRelease % 10)
}

///
/// This checks whether the supplied year can be accepted, according to the decadeOfRelease filters
/// supplied. If no filters are set, then the year will be accepted, otherwise it'll only be accepted
/// if the year falls within one of the decades in the Set.
///
const canAcceptYear = (decadeOfReleaseFilters: Set<number>, yearOfRelease: number): boolean => {
  if (decadeOfReleaseFilters.size === 0) {
    // No filters set - so accept everything.
    return true
  }

  // Get the decade filter to use.
  const decadeFilterYear = yearOfReleaseToDecadeFilter(yearOfRelease)

  // Now we can accept the year if the decadeFilter is set.
  return decadeOfReleaseFilters.has(decadeFilterYear)
}

///
/// Returns true of the track can be accepted because it was added to the library at or after
/// the currently set "cutoff" timestamp.
///
const canAcceptDateAdded = (
  dateAddedFilter: DateAddedFilter,
  trackDateAddedTimestamp: number,
): boolean => {
  return trackDateAddedTimestamp >= dateAddedFilter.cutoffTimestamp
}

///
/// Returns true if the filters are such that we can accept this track.
///
export const checkFilterMatch = (trackInfo: TrackInfo, filterData: FilterData): boolean => {
  // Can we accept the track based on decade-of-release?
  if (canAcceptYear(filterData.decadeOfReleaseFilters, trackInfo.releaseYear) === false) {
    // This track is not within an appropriate year.
    return false
  } else if (
    canAcceptDateAdded(filterData.dateAddedFilter, trackInfo.dateAddedTimestamp) === false
  ) {
    // The track was added before our cutoff timestamp.
    return false
  }

  return true
}
