import {createContext, useCallback, useContext, useState} from 'react'

//
// The actual store for the search terms.
//
export type SearchData = {
  track: string
  artist: string
  genre: string
}

const searchDataInit = (): SearchData => {
  return {track: '', artist: '', genre: ''}
}

///
/// The actions for the search terms.
///
type SearchActions = {
  setTrackSearchTerm: (term: string) => void
  setArtistSearchTerm: (term: string) => void
  setGenreSearchTerm: (term: string) => void
}

type SearchContextStore = {
  data: SearchData
  actions: SearchActions | undefined
}

//
// Create the actual context.
//
const SearchContext = createContext<SearchContextStore>({
  data: searchDataInit(),
  actions: undefined,
})

//
// Internal method to get the context - all external methods wrap around this, and this makes
// sure to assert if the context cannot be found.
//
const useSearchContext = () => {
  const searchContext: SearchContextStore = useContext(SearchContext)

  if (searchContext.actions === undefined) {
    throw new Error(
      'The component using the search context needs to be a descendant of the search provider',
    )
  }

  return searchContext
}

//
// The actual provider for the search bars - stores search terms for multiple pages.
//
export const SearchProvider = ({children}: {children: React.ReactNode}) => {
  const [searchData, setSearchData] = useState<SearchData>(searchDataInit())

  const setTrackSearchTerm = useCallback(
    (term: string) => {
      if (term !== searchData.track) {
        setSearchData({...searchData, track: term})
      }
    },
    [searchData],
  )

  const setArtistSearchTerm = useCallback(
    (term: string) => {
      if (term !== searchData.artist) {
        setSearchData({...searchData, artist: term})
      }
    },
    [searchData],
  )

  const setGenreSearchTerm = useCallback(
    (term: string) => {
      if (term !== searchData.genre) {
        setSearchData({...searchData, genre: term})
      }
    },
    [searchData],
  )

  const searchActions: SearchActions = {
    setTrackSearchTerm: setTrackSearchTerm,
    setArtistSearchTerm: setArtistSearchTerm,
    setGenreSearchTerm: setGenreSearchTerm,
  }

  return (
    <SearchContext.Provider value={{data: searchData, actions: searchActions}}>
      {children}
    </SearchContext.Provider>
  )
}

type UseSearch = {
  searchTerm: string
  setSearchTerm: (term: string) => void
}

const setTermPlaceholder = (term: string) => {
  throw new Error('Attempt to set search term on uninitialized provider.')
}

//
// This hook returns the search data for tracks.
//
export const useTrackSearch = (): UseSearch => {
  const searchContext = useSearchContext()
  return {
    searchTerm: searchContext.data.track,
    setSearchTerm: searchContext.actions
      ? searchContext.actions.setTrackSearchTerm
      : setTermPlaceholder,
  }
}

//
// This hook returns the search data for artists.
//
export const useArtistSearch = (): UseSearch => {
  const searchContext = useSearchContext()
  return {
    searchTerm: searchContext.data.artist,
    setSearchTerm: searchContext.actions
      ? searchContext.actions.setArtistSearchTerm
      : setTermPlaceholder,
  }
}

//
// This hook returns the search data for genres.
//
export const useGenreSearch = (): UseSearch => {
  const searchContext = useSearchContext()
  return {
    searchTerm: searchContext.data.genre,
    setSearchTerm: searchContext.actions
      ? searchContext.actions.setGenreSearchTerm
      : setTermPlaceholder,
  }
}
