import {TrackInfo} from 'src/helper/trackInfo'
import TrackEntry from './TrackEntry'
import {GroupedTracksInfo} from 'src/helper/greoupedTracksInfo'

//
// Props for the tracklist component.
//
type Props = {
  trackInfos?: TrackInfo[]
  groupedTrackInfos?: GroupedTracksInfo[]
}

///
/// Displays just a flat track-list.
///
const TracklistFlat = ({trackInfos}: {trackInfos: TrackInfo[]}) => {
  return (
    <>
      {trackInfos.map((trackInfo, index) => {
        return (
          <div key={index} style={{paddingBottom: 25}}>
            <TrackEntry trackInfo={trackInfo} />
          </div>
        )
      })}
    </>
  )
}

const TracklistGrouped = ({groupedTrackInfos}: {groupedTrackInfos: GroupedTracksInfo[]}) => {
  return (
    <>
      {groupedTrackInfos.map((groupedTracksInfo, index) => {
        return (
          <div key={index}>
            <h5 style={{width: '100%', textAlign: 'center'}}>{groupedTracksInfo.name}</h5>
            <TracklistFlat trackInfos={groupedTracksInfo.tracks} />
          </div>
        )
      })}
    </>
  )
}

//
// The component for displaying all the tracks in a list - the assumption is that the tracks are all
// sorted, and filtered. This can show a flat track list, but also a list of grouped tracks too.
//
export const TrackList = ({trackInfos, groupedTrackInfos}: Props) => {
  if (trackInfos === undefined && groupedTrackInfos === undefined) {
    return null
  }

  return (
    <div style={{background: '#F7AD19', paddingTop: 20, paddingLeft: 10, paddingRight: 10}}>
      {trackInfos && !groupedTrackInfos && <TracklistFlat trackInfos={trackInfos} />}
      {groupedTrackInfos && !trackInfos && (
        <TracklistGrouped groupedTrackInfos={groupedTrackInfos} />
      )}
    </div>
  )
}
export default TrackList
