import React, {createContext, useContext, useState} from 'react'
import {PhonebookData} from 'src/helper/phonebook'

//
// The actual store for the database.
//
export type PhonebookContextStore = {
  phonebook: PhonebookData
}

//
// Create the actual context.
//
const PhonebookContext = createContext<PhonebookContextStore | undefined>(undefined)

//
// Internal method to get the context - all external methods wrap around this, and this makes
// sure to assert if the context cannot be found.
//
const usePhonebookContext = () => {
  const phonebook: PhonebookContextStore | undefined = useContext(PhonebookContext)

  if (phonebook === undefined) {
    throw new Error(
      'The componenet using the phonebook context needs to be a descendant of the phonebook provider',
    )
  }

  return phonebook
}

//
// The actual provider for the phone-book. Upon first run, will read and parse the phonebook
// data, and store it within the React state.
//
export const PhonebookProvider = ({children}: {children: React.ReactNode}) => {
  const [phonebook, setPhonebook] = useState<PhonebookContextStore | undefined>(undefined)

  if (phonebook === undefined) {
    // Set an empty phonebook - before fetching the real one.
    setPhonebook({phonebook: {artists: new Map(), tracks: new Map(), genres: new Map()}})

    // Need to load the phonebook data.
    fetch('phonebook_json.json?' + new Date().getTime())
      .then((r) => r.json())
      .then((phonebookRaw) => {
        // We're going to fill a new phonebook.
        let phonebookParsed: PhonebookData = {
          artists: new Map(),
          tracks: new Map(),
          genres: new Map(),
        }

        // Go through all the artists.
        for (const [key, value] of Object.entries(phonebookRaw.artists)) {
          phonebookParsed.artists.set(parseInt(key), {
            name: (value as any).name,
            trackIds: (value as any).track_ids,
          })
        }

        // Go through all the genres.
        for (const [key, value] of Object.entries(phonebookRaw.genres)) {
          phonebookParsed.genres.set(parseInt(key), {
            name: (value as any).name,
            trackIds: (value as any).track_ids,
          })
        }

        // Now all the tracks.
        for (const [key, value] of Object.entries(phonebookRaw.tracks)) {
          phonebookParsed.tracks.set(parseInt(key), {
            title: (value as any).title,
            featuring: (value as any).featuring,
            artistId: (value as any).artist_id,
            phoneNumber: (value as any).phone_number,
            release_year: (value as any).release_year,
            date_added_timestame: (value as any).date_added_timestamp,
          })
        }

        // Update the phonebook with the real (fetched) data.
        setPhonebook({phonebook: phonebookParsed})
      })
  }

  return (
    <PhonebookContext.Provider value={phonebook as PhonebookContextStore}>
      {children}
    </PhonebookContext.Provider>
  )
}

//
// This hook returns the phonebook.
//
const usePhonebook = (): PhonebookContextStore => {
  return usePhonebookContext()
}
export default usePhonebook
