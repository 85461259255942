import {useEffect} from 'react'
import {Container} from 'react-bootstrap'
import {Route, Routes, useNavigate} from 'react-router-dom'
import usePhonebook, {PhonebookProvider} from './providers/phonebook'
import TracksPage from './components/TracksPage'
import {Routes as AppRoutes} from './components/Navigation'
import {SearchProvider} from './providers/search'
import ArtistsPage from './components/ArtistsPage'
import GenresPage from './components/GenresPage'
import {FilterProvider} from './providers/filter'
import FilterControlsModal from './components/Filter/controlsModal'
import NotFound from './components/NotFound'

///
/// This root component exits simply so that we can redirect to the "tracks" route.
///
const Root = () => {
  const navigate = useNavigate()

  useEffect(() => navigate(AppRoutes.tracks))

  return null
}

///
/// The base app component runs the app with all content, and displays a loading screen should
/// the phone-book not be loaded.
///
const BaseApp = () => {
  const {phonebook} = usePhonebook()

  if (phonebook.tracks.size === 0) {
    // There are no tracks, so display some text.
    return (
      <div
        style={{
          textAlign: 'center',
          marginTop: '100px',
        }}>
        <h3 style={{color: '#AAAAAA'}}>Loading the phone-book...</h3>
      </div>
    )
  }

  return (
    <>
      <Routes>
        <Route path="/tracks" element={<TracksPage />} />
        <Route path="/artists" element={<ArtistsPage />} />
        <Route path="/genres" element={<GenresPage />} />
        <Route path="/" element={<Root />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <FilterControlsModal />
    </>
  )
}

function App() {
  return (
    <PhonebookProvider>
      <FilterProvider>
        <SearchProvider>
          <Container style={{paddingLeft: 0, paddingRight: 0, maxWidth: '800px'}}>
            <>
              <BaseApp />
              <div style={{textAlign: 'right', paddingRight: 10}}>
                <p style={{color: '#444444'}}>The Amazing Rotary Jukebox: (C) Buzzneon 2024</p>
              </div>
            </>
          </Container>
        </SearchProvider>
      </FilterProvider>
    </PhonebookProvider>
  )
}

export default App
