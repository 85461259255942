import reduceToCompare from './reduceToCompare'
import {checkSearchMatch} from './search'
import {FilterData, checkFilterMatch} from './filter'

//
// General information for a track.
//
export type TrackInfo = {
  title: string
  artist: string
  featuring: string | null
  phoneNumber: string
  dateAddedTimestamp: number
  releaseYear: number
}

//
// This will sort the track infos into display order.
//
export const trackInfoSort = (trackInfos: TrackInfo[]) => {
  trackInfos.sort((a, b) => reduceToCompare(a.title).localeCompare(reduceToCompare(b.title)))
}

///
/// This will filter a single track-info - returning it if it can be accepted, and returning
/// undefined if the track is not accepted.
///
/// If the searchTerm is an empty string, then the title will always match.
///
export const trackInfoFilter = (
  searchTerm: string,
  filterData: FilterData,
  srcTrack: TrackInfo,
): TrackInfo | undefined => {
  const canAcceptTrack = (track: TrackInfo): boolean => {
    if (checkSearchMatch(searchTerm, track.title) === false) {
      // Search term doesn't match - we're done.
      return false
    }

    if (checkFilterMatch(track, filterData) === false) {
      // The filter excludes this track.
      return false
    }

    // We can accept this track!
    return true
  }

  if (canAcceptTrack(srcTrack)) {
    // The track was accepted!
    return srcTrack
  }

  // The track was not accepted.
  return undefined
}

///
/// This will filter and sort an array of track-infos - according to the searchTerm and the filterData. The
/// returned array of TrackInfos will all match the search term and the filters.
///
/// If the searchTerm is an empty string, then all titles will match.
///
export const trackInfosFilterAndSort = (
  searchTerm: string,
  filterData: FilterData,
  srcTracks: TrackInfo[],
): TrackInfo[] => {
  // Tracks which are accepted will be placed here.
  let filteredTracks: TrackInfo[] = []

  // Process the source tracks.
  srcTracks.forEach((srcTrack) => {
    const filteredTrack = trackInfoFilter(searchTerm, filterData, srcTrack)
    if (filteredTrack) {
      filteredTracks.push(filteredTrack)
    }
  })

  // Now sort the filtered tracks.
  trackInfoSort(filteredTracks)

  // And return the results!
  return filteredTracks
}
