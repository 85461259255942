import {Routes} from './Navigation'
import ContentPage from './ContentPage'
import usePhonebook from '../providers/phonebook'
import {useMemo} from 'react'
import {prepareSearch} from '../helper/search'
import {useGenreSearch} from '../providers/search'
import GroupedTracksList from './GroupedTracksList'
import {phoneBookGenresFilterAndSort} from 'src/helper/phonebook'
import {useFilterData} from 'src/providers/filter'

const GenresPage = () => {
  const {searchTerm, setSearchTerm} = useGenreSearch()
  const {filterData} = useFilterData()
  const {phonebook} = usePhonebook()

  const genreInfos = useMemo(() => {
    return phoneBookGenresFilterAndSort(phonebook, prepareSearch(searchTerm), filterData)
  }, [searchTerm, phonebook, filterData])

  ///
  /// Called to actually perform the search.
  ///
  const onPerformSearch = (searchTermUpdate: string) => {
    setSearchTerm(searchTermUpdate)
  }

  ///
  /// Called when the search-term is cleared - need to display all tracks.
  ///
  const onSearchTermClear = () => {
    setSearchTerm('')
  }

  // Due to how much duplication there is for tracks under the genres, we only want to show
  // the "see all tracks" trigger if there are less than half the total number of genres.
  const minToShowAllTracks = phonebook.genres.size / 2

  return (
    <ContentPage
      activeRoute={Routes.tracks}
      onPerformSearch={onPerformSearch}
      onSearchTermClear={onSearchTermClear}
      initialSearchTerm={searchTerm}
      itemTextSingular="genre"
      hasData={genreInfos.length > 0}
      placeholderText={`Search ${phonebook.genres.size} genres...`}>
      <GroupedTracksList groupedTracksInfos={genreInfos} minToShowAllTracks={minToShowAllTracks} />
    </ContentPage>
  )
}

export default GenresPage
