import reduceToCompare from './reduceToCompare'
import {TrackInfo} from './trackInfo'

//
// Info for the artist to display - including all the tracks..
//
export type ArtistInfo = {
  name: string
  tracks: TrackInfo[]
}

//
// This will sort the artist infos into display order.
//
export const artistInfoSort = (artistInfos: ArtistInfo[]) => {
  artistInfos.sort((a, b) => reduceToCompare(a.name).localeCompare(reduceToCompare(b.name)))
}
