import {Col, Container, Row, Stack} from 'react-bootstrap'
import {TrackInfo} from 'src/helper/trackInfo'

//
// The component for displaying a track in the browse list.
//
const TrackEntry = ({trackInfo}: {trackInfo: TrackInfo}) => {
  const featuring =
    trackInfo.featuring && trackInfo.featuring !== trackInfo.artist
      ? trackInfo.featuring
      : undefined
  return (
    <Container className="ms-auto" style={{width: '100%'}}>
      <Row>
        <Col>
          <Stack direction="horizontal" gap={3}>
            <div>{trackInfo.title}</div>
            <div className="ms-auto font-monospace" style={{whiteSpace: 'nowrap'}}>
              {trackInfo.phoneNumber}
            </div>
          </Stack>
        </Col>
      </Row>
      <Row>
        <Col style={{paddingLeft: '10vw'}}>By: {trackInfo.artist}</Col>
      </Row>
      {featuring && (
        <Row>
          <Col style={{paddingLeft: '10vw'}}>Featuring: {featuring}</Col>
        </Row>
      )}
    </Container>
  )
}

export default TrackEntry
