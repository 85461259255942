//
// This is what an artist looks like in the phone-book.

import {ArtistInfo, artistInfoSort} from './artistInfo'
import {FilterData} from './filter'
import {GenreInfo, genreInfoSort} from './genreInfo'
import {checkSearchMatch} from './search'
import {TrackInfo, trackInfoFilter, trackInfoSort, trackInfosFilterAndSort} from './trackInfo'

//
export type PhonebookArtist = {
  name: string
  trackIds: number[]
}

//
// This is what a genre looks like in the phone-book.
//
export type PhonebookGenre = {
  name: string
  trackIds: number[]
}

//
// This is what a track looks like in the phone-book.
//
export type PhonebookTrack = {
  title: string
  featuring: string | null
  phoneNumber: string
  artistId: number
  release_year: number
  date_added_timestame: number
}

//
// This is the phonebook data
//
export type PhonebookData = {
  artists: Map<number, PhonebookArtist>
  genres: Map<number, PhonebookGenre>
  tracks: Map<number, PhonebookTrack>
}

///
/// This will filter and sort all the tracks in the phonebook, returning an array of
/// TrackInfo structs (sorted alphabetically). If the combination of search term and
/// filters result in no matched tracks, then the array will be empty.
///
export const phoneBookTracksFilterAndSort = (
  phonebook: PhonebookData,
  searchTerm: string,
  filterData: FilterData,
): TrackInfo[] => {
  // Tracks which are accepted will be placed here.
  let filteredTracks: TrackInfo[] = []

  // Process the source tracks.
  phonebook.tracks.forEach((phonebookTrack) => {
    // Need to make a trackInfo for the phonebook track.

    // Start with the track's artist (so we can know the name)
    const phonebookArtist = phonebook.artists.get(phonebookTrack.artistId)
    if (phonebookArtist) {
      // Now make the track.
      const trackInfo: TrackInfo = {
        title: phonebookTrack.title,
        artist: phonebookArtist.name,
        featuring: phonebookTrack.featuring,
        releaseYear: phonebookTrack.release_year,
        dateAddedTimestamp: phonebookTrack.date_added_timestame,
        phoneNumber: phonebookTrack.phoneNumber,
      }

      // Now, we can filter the track based on the search term and filter data.
      const filteredTrack = trackInfoFilter(searchTerm, filterData, trackInfo)
      if (filteredTrack) {
        filteredTracks.push(filteredTrack)
      }
    }
  })

  // Now sort the filtered tracks.
  trackInfoSort(filteredTracks)

  // And return the results!
  return filteredTracks
}

///
/// This will make an array of TrackInfo, from an array of phonebook track-IDs. The returned array will
/// not be sorted in any way.
const phonebookTrackIDsToTrackInfo = (
  phonebookData: PhonebookData,
  trackIDs: number[],
): TrackInfo[] => {
  let trackInfos: TrackInfo[] = []

  // GO through all the track-ids.
  trackIDs.forEach((trackID) => {
    // Get the phonebook track for this ID
    const phonebookTrack = phonebookData.tracks.get(trackID)
    if (phonebookTrack !== undefined) {
      // We have a track! Let's see if we can find an artist.
      const phonebookArtist = phonebookData.artists.get(phonebookTrack.artistId)
      if (phonebookArtist !== undefined) {
        // Make the trackInfo.
        trackInfos.push({
          title: phonebookTrack.title,
          artist: phonebookArtist.name,
          featuring: phonebookTrack.featuring,
          releaseYear: phonebookTrack.release_year,
          dateAddedTimestamp: phonebookTrack.date_added_timestame,
          phoneNumber: phonebookTrack.phoneNumber,
        })
      }
    }
  })

  return trackInfos
}

///
/// This will filter and sort a phonebook group (like a single artist or genre); first making sure that
/// the name of the group matches the search term, and then making sure all the tracks match the filtering
/// rules.
///
/// Returns undefined if the group name doesn't match the search term, also returns undefined if no tracks
/// match the filtering controls. Returns an array of TrackInfo (sorted) with the tracks for this group
/// which match the filtering rules - there will be at least one track.
///
const phonebookGroupFilterAndSort = (
  phonebookData: PhonebookData,
  groupName: string,
  trackIDs: number[],
  searchTerm: string,
  filterData: FilterData,
): TrackInfo[] | undefined => {
  // Make sure the group's name matches the search results.
  if (checkSearchMatch(searchTerm, groupName)) {
    // The name of the group matches!

    // Get all the TrackInfos for the IDs in the group.
    const srcTracks = phonebookTrackIDsToTrackInfo(phonebookData, trackIDs)

    // Now filter the tracks - we give an empty search-term because it's the GROUP NAME being searched,
    // not the tracks. However the tracks do still need to be filtered.
    const filteredTracks = trackInfosFilterAndSort('', filterData, srcTracks)
    if (filteredTracks.length > 0) {
      // There is at least one track!
      return filteredTracks
    }
  }

  // No match (or no tracks).
  return undefined
}

///
/// This will filter and sort all the tracks in all artists in the phonebook, returning an array of
/// ArtistInfo structs (sorted alphabetically, with all tracks sorted alphabetically). If the combination
/// of search term and filters result in no matched tracks, then the array will be empty.
///
/// It's safe to assume that no artists will be empty - they will contain at least 1 track.
///
export const phoneBookArtistsFilterAndSort = (
  phonebookData: PhonebookData,
  searchTerm: string,
  filterData: FilterData,
): ArtistInfo[] => {
  // Artists which are accepted will be placed here.
  let filteredArtists: ArtistInfo[] = []

  // Process the source artists.
  phonebookData.artists.forEach((phonebookArtist) => {
    const filteredTracks = phonebookGroupFilterAndSort(
      phonebookData,
      phonebookArtist.name,
      phonebookArtist.trackIds,
      searchTerm,
      filterData,
    )
    if (filteredTracks && filteredTracks.length > 0) {
      // This Artist is good!
      filteredArtists.push({
        name: phonebookArtist.name,
        tracks: filteredTracks,
      })
    }
  })

  // Sort the artists.
  artistInfoSort(filteredArtists)

  // Return the results.
  return filteredArtists
}

///
/// This will filter and sort all the tracks in all genres in the phonebook, returning an array of
/// GenreInfo structs (sorted alphabetically, with all tracks sorted alphabetically). If the combination
/// of search term and filters result in no matched tracks, then the array will be empty.
///
/// It's safe to assume that no genres will be empty - they will contain at least 1 track.
///
export const phoneBookGenresFilterAndSort = (
  phonebookData: PhonebookData,
  searchTerm: string,
  filterData: FilterData,
): GenreInfo[] => {
  // Genres which are accepted will be placed here.
  let filteredGenres: GenreInfo[] = []

  // Process the source genres.
  phonebookData.genres.forEach((phonebookGenre) => {
    const filteredTracks = phonebookGroupFilterAndSort(
      phonebookData,
      phonebookGenre.name,
      phonebookGenre.trackIds,
      searchTerm,
      filterData,
    )
    if (filteredTracks && filteredTracks.length > 0) {
      // This genre is good!
      filteredGenres.push({
        name: phonebookGenre.name,
        tracks: filteredTracks,
      })
    }
  })

  // Sort the genres.
  genreInfoSort(filteredGenres)

  // Return the results.
  return filteredGenres
}
