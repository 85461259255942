import {Button, Image} from 'react-bootstrap'
import filter from '../../assets/icon_filter_50.png'
import {useFilterTriggerActions} from 'src/providers/filter'

type Props = {
  className?: string
}

const FilterTrigger: React.FC<Props> = ({className}: Props) => {
  const {isFilterActive, setFilterControlsUI} = useFilterTriggerActions()

  const variant = isFilterActive() ? 'success' : 'secondary'
  return (
    <div>
      <Button size="sm" variant={variant} onClick={() => setFilterControlsUI('open')}>
        <Image width="20dv" src={filter} />
      </Button>
    </div>
  )
}

export default FilterTrigger
