import {Button, Modal, Stack} from 'react-bootstrap'
import {useFilterData, useFilterTriggerActions} from 'src/providers/filter'
import DateDropdown from './DateDropdown'
import {useEffect, useState} from 'react'
import DecadeOfRelease, {DecadeFilter, decadeFilterInit} from './DecadeOfRelease'
import {FilterData, dateInfoTable} from 'src/helper/filter'

///
/// This runs the modal which presents the UI for modifying the filtering controls.
///
const FilterControlsModal = () => {
  const [dateInfoIndex, setDateInfoIndex] = useState<number>(0)
  const [decadeFilter, setDecadeFilter] = useState<DecadeFilter>(decadeFilterInit())

  const {checkFilterControlsUIActive, setFilterControlsUI} = useFilterTriggerActions()
  const {filterData, setFilterData} = useFilterData()

  useEffect(() => {
    setDateInfoIndex(filterData.dateAddedFilter.infoIndex)
    setDecadeFilter({filterDecades: filterData.decadeOfReleaseFilters})
  }, [filterData])

  // This will save the filter options, and close the modal.
  const saveAndCloseModal = () => {
    // Generate the cutoff timestamp for the index.
    let cutoffTimestamp = 0 // Assume all.
    if (dateInfoTable[dateInfoIndex].delta) {
      const timestampNow = Math.floor(Date.now() / 1000)
      cutoffTimestamp = timestampNow - dateInfoTable[dateInfoIndex].delta!
    }
    const newFilterData: FilterData = {
      dateAddedFilter: {
        infoIndex: dateInfoIndex,
        cutoffTimestamp: cutoffTimestamp,
      },
      decadeOfReleaseFilters: decadeFilter.filterDecades,
    }
    setFilterData(newFilterData)
    setFilterControlsUI('closed')
  }

  // This will clear/reset all the filter options.
  const resetAll = () => {
    // Reset the date selector.
    setDateInfoIndex(0)

    // Clear the decade filter.
    setDecadeFilter(decadeFilterInit())
  }

  // Present the filtering modal.
  return (
    <Modal
      show={checkFilterControlsUIActive()}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Filter Options</Modal.Title>
        <Button className="ms-auto" size="sm" onClick={resetAll} variant="secondary">
          Clear All
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Stack>
          <div className="text-center" style={{marginBottom: 10}}>
            Decade Of Release
          </div>
          <DecadeOfRelease onDecadeFilterUpdate={setDecadeFilter} decadeFilter={decadeFilter} />
          <hr />
          <DateDropdown
            dateInfoIndex={dateInfoIndex}
            onDateInfoSelected={(newDateInfo) => setDateInfoIndex(newDateInfo)}
          />
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={saveAndCloseModal} variant="primary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FilterControlsModal
