import {Routes} from './Navigation'
import ContentPage from './ContentPage'
import {useTrackSearch} from '../providers/search'
import usePhonebook from '../providers/phonebook'
import {useMemo} from 'react'
import TrackList from './TrackList'
import {prepareSearch} from '../helper/search'
import {phoneBookTracksFilterAndSort} from 'src/helper/phonebook'
import {useFilterData} from 'src/providers/filter'

const TracksPage = () => {
  const {searchTerm, setSearchTerm} = useTrackSearch()
  const {filterData} = useFilterData()
  const {phonebook} = usePhonebook()

  const trackInfos = useMemo(() => {
    return phoneBookTracksFilterAndSort(phonebook, prepareSearch(searchTerm), filterData)
  }, [searchTerm, phonebook, filterData])

  ///
  /// Called to actually perform the search.
  ///
  const onPerformSearch = (searchTermUpdate: string) => {
    setSearchTerm(searchTermUpdate)
  }

  ///
  /// Called when the search-term is cleared - need to display all tracks.
  ///
  const onSearchTermClear = () => {
    setSearchTerm('')
  }

  return (
    <ContentPage
      activeRoute={Routes.tracks}
      onPerformSearch={onPerformSearch}
      onSearchTermClear={onSearchTermClear}
      initialSearchTerm={searchTerm}
      itemTextSingular="track"
      hasData={trackInfos.length > 0}
      placeholderText={`Search ${phonebook.tracks.size} tracks...`}>
      <TrackList trackInfos={trackInfos} />
    </ContentPage>
  )
}

export default TracksPage
