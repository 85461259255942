//
// This will take the raw string, and return a version that should be used in
// searches. For example, if the raw string is:
//  * "...oops" it'll return "oops"
//  * "(this is a) song" it'll return "song"
const reduceToCompare = (raw: string): string => {
  // First, trim the input.
  const sanitized = raw.trim()

  // Now, find the first alpha-numeric character.
  const alphaIndex = sanitized.search(/[a-zA-Z0-9]/)

  if (alphaIndex < 0) {
    // There are no alpha-numeric characters.
    return sanitized
  }

  let startAtAlpha = sanitized.substr(alphaIndex)

  // Now see if there is any (blah) at the start of the track.
  if (sanitized.search(/^\(.*\).*/) < 0) {
    // There are no leading (blah) so return what we had.
    return startAtAlpha
  }

  // There was a leading (blah), so chop everything up to (and including) the
  // closing ).
  const closingParen = sanitized.indexOf(')')

  if (closingParen < 0) {
    // Couldn't find a closing paren - which should be impossible.
    return startAtAlpha
  }

  if (sanitized.length <= closingParen + 1) {
    // There's nothing else in the string!
    return startAtAlpha
  }

  // Get the string which follows the closing paren.
  let parenTrimmed = sanitized.substring(closingParen + 1).trim()
  if (parenTrimmed.length === 0) {
    // We trimmed everything away!
    return startAtAlpha
  }

  return parenTrimmed
}

export default reduceToCompare
