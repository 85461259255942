import reduceToCompare from './reduceToCompare'
import {TrackInfo} from './trackInfo'

//
// Info for the genre to display - including all the tracks..
//
export type GenreInfo = {
  name: string
  tracks: TrackInfo[]
}

//
// This will sort the genre infos into display order.
//
export const genreInfoSort = (genreInfos: GenreInfo[]) => {
  genreInfos.sort((a, b) => reduceToCompare(a.name).localeCompare(reduceToCompare(b.name)))
}
